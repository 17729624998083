import React, { useCallback, MouseEvent } from 'react'
import { SpacerV } from '../../composing/Spacing'
import { TradeFeedItem } from '@commonstock/common/src/api/feed'
import { useEphemeralModal, useModal } from '../modal/Modal'
import { useRefetchPostDependents } from '../posts/postHooks'
import EditPost from '../posts/EditPost'
import PseudoInputButton from 'src/components/PseudoInputButton'
import { HydratedUserProfile } from '@commonstock/common/src/api/profile'
import { useUser } from '../auth/AuthContext'
import { useFlags } from '../feature-flags/useFlags'

export function TradeReason({ tradeFeedItem, user }: { tradeFeedItem: TradeFeedItem; user: HydratedUserProfile }) {
  const { webTradeReasons } = useFlags()
  let [currentUser] = useUser()
  const refetchPostDependents = useRefetchPostDependents()
  const modal = useEphemeralModal()
  const { actions } = useModal()
  const onClickWhy = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation()
      modal(
        <EditPost
          onFinish={() => {
            refetchPostDependents()
            actions.close()
          }}
          prelinkedTrade={tradeFeedItem}
        />
      )
    },
    [actions, modal, refetchPostDependents, tradeFeedItem]
  )

  if (user.uuid !== currentUser?.user_uuid || !webTradeReasons) return null
  return (
    <>
      <SpacerV rem={0.5} />
      <PseudoInputButton onClick={onClickWhy} prompt="Why did you make this trade?" />
    </>
  )
}
