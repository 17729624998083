import React, { MouseEvent, useCallback, useMemo } from 'react';
import Tooltip from '../../components/Tooltip';
import { Share } from '../../theme/NewIcons';
import { CardIcon, Menu, MenuItem, shareIconClass } from '../../components/styles';
import { IconButton } from '../../composing/CSButton';
import { useEphemeralModal } from '../modal/Modal';
import { TextCaptionEmphasis } from 'src/composing/CSText';
import { SpacerH } from 'src/composing/Spacing';
import { flexCentered, relative } from 'src/theme/AtomicClasses';
import dynamic from 'next/dynamic';
import { isLoggedIn } from '@commonstock/common/src/auth';
import { JoinModalPublic } from 'src/scopes/public/JoinModalPublic';
import useLoggedOutModal from 'src/scopes/public/useLoggedOutModal';
import { useState } from 'react';
import { Routes } from 'src/scopes/nav/constants';
import useEventListener from '@commonstock/common/src/utils/useEventListener';
import { css } from '@linaria/core';
import { getFeedItemTypeBoolean } from '../content/utils';
import { FeedItem } from '@commonstock/common/src/api/feed';
import { useTheme } from 'src/theme/ThemeContext';
const TradeShareModal = dynamic(async () => {
  const module = await import('./ShareModal');
  return module.TradeShareModal;
});
const MemoShareModal = dynamic(async () => {
  const module = await import('./ShareModal');
  return module.MemoShareModal;
});
const PostShareModal = dynamic(async () => {
  const module = await import('./ShareModal');
  return module.PostShareModal;
});
type Props = {
  uuid: string;
  disabled?: boolean;
  feedItem: FeedItem;
};

function ShareIcon({
  uuid,
  disabled,
  feedItem
}: Props) {
  const {
    memo,
    post,
    trade
  } = getFeedItemTypeBoolean(feedItem);
  const modal = useEphemeralModal();
  const loggedOutModal = useLoggedOutModal(`Share`);
  const [isOpen, setIsOpen] = useState(false);
  const [wasCopied, setWasCopied] = useState(false);
  const tip = useMemo(() => {
    if (disabled) return 'Share disabled';
    if (trade) return 'Share trade';
    if (memo) return 'Share memo';
    if (post) return 'Share post';
    return '';
  }, [disabled, trade, memo, post]);
  const onClickSharePreview = useCallback((e: MouseEvent) => {
    e.preventDefault();

    if (!isLoggedIn()) {
      loggedOutModal('Join', <JoinModalPublic />);
      return;
    }

    const shareModal = trade ? <TradeShareModal tradeFeedItem={trade} /> : memo ? <MemoShareModal memoFeedItem={memo} /> : post ? <PostShareModal postFeedItem={post} /> : null;

    if (shareModal) {
      modal(shareModal);
      setIsOpen(false);
    }

    e.stopPropagation();
  }, [memo, loggedOutModal, modal, post, trade]);
  const onClickMenu = useCallback((e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if (!isLoggedIn()) {
      loggedOutModal('Join', <JoinModalPublic />);
      return;
    }

    setIsOpen(prev => !prev);
  }, [loggedOutModal]);
  const onClickCopy = useCallback((e: MouseEvent) => {
    e.preventDefault();
    const shareLink = trade ? Routes.tradeDetail(uuid) : memo ? Routes.memoDetail(uuid) : post ? Routes.postDetail(uuid) : '';
    navigator.clipboard.writeText(`${window.location.origin}${shareLink}`);
    setWasCopied(true);
  }, [memo, post, trade, uuid]);
  useEventListener('click', (e: any) => {
    if (!e.target.closest('#share-content-menu')) {
      setIsOpen(false);
      setWasCopied(false);
    }
  });
  const {
    isSmallMobile
  } = useTheme();
  return <CardIcon id="#share-content-menu" className={shareIconClass}>
      <Tooltip tip={tip} disable={isOpen}>
        <div className={relative}>
          <IconButton className={flexCentered} onClick={disabled ? undefined : onClickMenu} disabled={disabled} aria-label={'Share content'}>
            <Share size={1.25} />
            <SpacerH rem={0.5} />
            {!isSmallMobile && <TextCaptionEmphasis>Share</TextCaptionEmphasis>}
          </IconButton>
          <Menu className={wrapperPadding} hidden={!isOpen}>
            <MenuItem onClick={onClickCopy} isPositive={wasCopied}>
              {wasCopied ? 'Link copied!' : 'Copy link'}
            </MenuItem>
            <MenuItem onClick={onClickSharePreview}>Share preview</MenuItem>
          </Menu>
        </div>
      </Tooltip>
    </CardIcon>;
}

export default ShareIcon;
const wrapperPadding = "w7ki1ga";

require("../../../.linaria-cache/packages/oxcart/src/scopes/share/ShareIcon.linaria.module.css");