import React from 'react'
import { useGetPost } from '@commonstock/common/src/api/post'
import { PostFeedItem } from '@commonstock/common/src/api/feed'
import { useAuth } from '../auth/AuthContext'
import { ParentTypes, useGetComment } from '@commonstock/common/src/api/comment'
import { DetailCard, PaneColumn, PanelGrid } from '../../components/styles'
import { BrandSpinner } from '../../components/Spinner'
import { Spacing } from '../../composing/Spacing'
import CommentItem from '../comments/CommentItem'
import { cx } from '@linaria/core'
import RootComments from '../comments/RootComments'
import { PostContent } from './PostCard'
import { useRouter } from 'next/router'
import NotFound from '../../components/NotFound'
import { isLoggedIn } from '@commonstock/common/src/auth'
import { DetailContentWrapper } from '../content/DetailContentWrapper'
import PrivateContentCard from 'src/scopes/profile/PrivateContentCard'
import RelatedContent from 'src/scopes/discover/RelatedContent'
import { CardLayoutTypes } from 'src/scopes/feed/constants'
import { HydratedUserProfile } from '@commonstock/common/src/api/profile'

type Params = { postId: string; commentId?: string }

type Props = {
  isModal?: boolean
  postFeedItem?: PostFeedItem
  privateProfile?: HydratedUserProfile
  uuid?: string
  cardLayout: CardLayoutTypes
}
function PostDetail({ isModal, postFeedItem, privateProfile, uuid, cardLayout }: Props) {
  const router = useRouter()
  const { commentId, postId } = router.query as Params
  const { isModerator } = useAuth()

  const postUuid = uuid || postId || postFeedItem?.uuid || ''

  const [fetchPost, fail] = useGetPost(
    { meta: { uuid: postUuid } },
    { paused: (!isLoggedIn() && !!privateProfile) || !postUuid }
  )
  const [fetchComment, failComment] = useGetComment({ meta: { uuid: commentId || '' } }, { paused: !commentId })
  postFeedItem = fetchPost || postFeedItem
  const rootUserUuid = postFeedItem?.user_uuid || ''

  if ((!isModal && (fail || failComment)) || (!isLoggedIn() && privateProfile)) {
    return (
      <PanelGrid>
        <PaneColumn>
          {privateProfile ? (
            <PrivateContentCard
              user={privateProfile}
              title="This post is from a private account."
              subtitle={`Follow ${privateProfile.name} on Commonstock to view this post.`}
            />
          ) : (
            <NotFound />
          )}
        </PaneColumn>
        <PaneColumn />
      </PanelGrid>
    )
  }
  if (isModal && fail) {
    return (
      <DetailCard className={cx(isModal && 'modal')}>
        <NotFound />
      </DetailCard>
    )
  }
  if (!postFeedItem || (commentId && !fetchComment)) {
    if (isModal) {
      return (
        <DetailCard className={cx(isModal && 'modal', 'loading')}>
          <BrandSpinner />
        </DetailCard>
      )
    }
    return (
      <PanelGrid>
        <PaneColumn>
          <DetailCard className={cx(isModal && 'modal', 'loading')}>
            <BrandSpinner />
          </DetailCard>
        </PaneColumn>
        <PaneColumn />
      </PanelGrid>
    )
  }
  if (isModerator && fetchComment?.archived_at) {
    return (
      <PanelGrid>
        <PaneColumn>
          <Spacing padding={[0, 1, 1]}>
            <DetailCard>
              <CommentItem
                comment={fetchComment}
                rootType={ParentTypes.POST}
                rootUuid={postUuid}
                rootUserUuid={rootUserUuid}
                cardLayout={cardLayout}
              />
            </DetailCard>
          </Spacing>
        </PaneColumn>
        <PaneColumn />
      </PanelGrid>
    )
  }
  if (!isModal) {
    return (
      <PanelGrid>
        <PaneColumn>
          <PostPageContent
            isModal={false}
            postFeedItem={postFeedItem}
            postUuid={postUuid}
            rootUserUuid={rootUserUuid}
            cardLayout={cardLayout}
          />
        </PaneColumn>
        <PaneColumn>
          <RelatedContent feedItem={postFeedItem} />
        </PaneColumn>
      </PanelGrid>
    )
  }
  return (
    <PostPageContent
      isModal={isModal}
      postFeedItem={postFeedItem}
      postUuid={postUuid}
      rootUserUuid={rootUserUuid}
      cardLayout={cardLayout}
    />
  )
}

export default PostDetail

type PostMainContentProps = {
  isModal?: boolean
  postFeedItem: PostFeedItem
  postUuid: string
  rootUserUuid: string
  cardLayout: CardLayoutTypes
}
function PostPageContent({ isModal, postFeedItem, postUuid, rootUserUuid, cardLayout }: PostMainContentProps) {
  const initialComments = postFeedItem?.resources.posts[postFeedItem.uuid]?.comments

  return (
    <DetailContentWrapper feedItem={postFeedItem} isModal={isModal} cardLayout={cardLayout}>
      <PostContent postFeedItem={postFeedItem} detail cardLayout={cardLayout} />
      <RootComments
        initialComments={initialComments}
        uuid={postUuid}
        type={ParentTypes.POST}
        isModal={!!isModal}
        rootUserUuid={rootUserUuid}
        cardLayout={cardLayout}
      />
    </DetailContentWrapper>
  )
}
