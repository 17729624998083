import React from 'react'
import { Formatter } from '../text-editor/csMarkdown'
import MemoPreview from './MemoPreview'
import { MemoFeedItem } from '@commonstock/common/src/api/feed'
import { destructMemoFeedItem } from './utils'

type Props = {
  memoFeedItem: MemoFeedItem
  preview?: boolean
}

function MemoView({ memoFeedItem, preview }: Props) {
  let { memo, mentionAttachments } = destructMemoFeedItem(memoFeedItem)

  if (!memo) return null
  // @TODO this is maybe not the most elegant solution, but sometimes BE returns summary and no body (feed)
  let markdownText = memo.body || memo.summary

  return preview ? (
    <MemoPreview memoFeedItem={memoFeedItem} />
  ) : (
    <Formatter text={markdownText} mentions={mentionAttachments} />
  )
}

export default React.memo(MemoView)
