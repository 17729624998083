import { createUseFetch, createUseFetchAction } from '@commonstock/client/src/react/createUseFetch'
import { HttpMethods } from '@commonstock/client/src/constants'
import cacheLast from '@commonstock/client/src/utils/cacheLast'
import { AssetMention } from '../types/mentions'
import { HydratedUserProfile } from './profile'
import { Likes, LikeTypes } from './like'

export type CommentSummary = {
  count: number
  comments: Comment[]
}

export enum ParentTypes {
  MEMO_PLUS = 'MEMO_PLUS',
  TRADE = 'TRADE',
  COMMENT = 'COMMENT',
  POST = 'POST'
}

export type Comment = {
  uuid: string
  item_type: ParentTypes.COMMENT
  parent_uuid: string
  parent_type: ParentTypes
  user_uuid: string
  body: string
  media: string[]
  created_at: string
  created_at_timestamp: number
  updated_at: string
  updated_at_timestamp: number
  archived_at: string | null
  archived_at_timestamp: number | null
  likes: Likes
  user: HydratedUserProfile
  asset_mentions: AssetMention[]
  user_mentions: HydratedUserProfile[]
  comments: CommentMetaSum
  replies: Comment[]
  root_type: string
  root_uuid: string

  // For optimisticall matters
  unsynced?: boolean
}

export type CommentMetaSum = {
  count: number
  item_uuid: string
}

type GetCommentsParams = {
  meta: {
    uuid: string
  }
}
export const useGetComments = createUseFetch<CommentSummary, GetCommentsParams>({
  key: 'get-comments',
  path: ({ meta: { uuid } }) => `/comments/${uuid}`
})

export const useGetCachedComments = cacheLast(useGetComments)

type GetCommentParams = {
  meta: {
    uuid: string
  }
}
export const useGetComment = createUseFetch<Comment, GetCommentParams>({
  key: 'get-comment',
  path: ({ meta: { uuid } }) => `/comment/${uuid}`
})

export type PostCommentParams = {
  json: {
    parent_uuid: string
    parent_type: LikeTypes
    body: string
    media: string[]
  }
}

export const usePostCommentAction = createUseFetchAction<Comment, PostCommentParams>({
  key: 'create-comment',
  method: HttpMethods.Post,
  path: '/comment/create'
})

export type UpdateCommentParams = {
  json: {
    uuid: string
    body: string
    media: string[]
  }
}

export const useUpdateCommentAction = createUseFetchAction<Comment, UpdateCommentParams>({
  key: 'update-comment',
  method: HttpMethods.Put,
  path: ({ json: { uuid } }) => `/comment/${uuid}`
})

type DeleteCommentParams = {
  meta: {
    uuid: string
  }
}

export const useDeleteCommentAction = createUseFetchAction<void, DeleteCommentParams>({
  key: 'delete-comment',
  method: HttpMethods.Delete,
  path: ({ meta: { uuid } }) => `/comment/${uuid}`
})

export const useUnarchiveCommentAction = createUseFetchAction<void, DeleteCommentParams>({
  key: 'comment-unarchive',
  method: HttpMethods.Post,
  path: ({ meta: { uuid } }) => `/comment/${uuid}/unarchive`
})
