import { FeedResourceType, MemoFeedItem, MemoResource, TradeFeedItem } from '@commonstock/common/src/api/feed'
import { useMemo } from 'react'
import LinkedResourceCarousel from 'src/components/LinkedResourceCarousel'
import { SpacerV } from 'src/composing/Spacing'
import MemoCardSmall from './MemoCardSmall'

type Props = {
  tradeFeedItem: TradeFeedItem
  memos: MemoResource[]
}

function LinkedMemos({ tradeFeedItem, memos }: Props) {
  const memofeedItems: MemoFeedItem[] = useMemo(() => {
    return memos.map(memo => ({
      ...tradeFeedItem,
      resource_type: FeedResourceType.MEMO_PLUS,
      uuid: memo.uuid,
      user_uuid: memo.user_uuid
    }))
  }, [memos, tradeFeedItem])

  if (!memofeedItems.length) return null
  return (
    <>
      <LinkedResourceCarousel label="memos" height={5.9375}>
        {memofeedItems.map(memoFeedItem => (
          <MemoCardSmall key={memoFeedItem.uuid} memoFeedItem={memoFeedItem} noBorder isLinked />
        ))}
      </LinkedResourceCarousel>
      <SpacerV />
    </>
  )
}

export default LinkedMemos
