import React, { ReactNode, useCallback } from 'react';
import { cardLinkPointerClass } from '../../components/styles';
import { SpacerV } from '../../composing/Spacing';
import ContentHeader from '../content/ContentHeader';
import { Routes } from '../nav/constants';
import MemoView from './MemoView';
import ContentFooterActions from '../content/ContentFooterActions';
import { TagItemType } from '@commonstock/common/src/types';
import TagCloud from '../profile/TagCloud';
import CardCommentSummary from '../../components/CardCommentSummary';
import { Formatter, memoMarkdown } from '../text-editor/csMarkdown';
import { css } from '@linaria/core';
import { ParentTypes } from '@commonstock/common/src/api/comment';
import MentionsContext from '../text-editor/Mentions';
import { $BodyEmphasis } from '../../theme/Types';
import { FeedReason, MemoFeedItem } from '@commonstock/common/src/api/feed';
import { destructMemoFeedItem } from '../memo/utils';
import { ModalScope, useEphemeralModal } from '../modal/Modal';
import { trackMemoViewAction } from '../analytics/events.v1';
import { useTrackFeedItemImpression } from '../analytics/events.v1/impression';
import LinkedTrades from 'src/scopes/trade/LinkedTrades';
import dynamic from 'next/dynamic';
import CSLink from 'src/composing/CSLink';
import { isLoggedIn } from '@commonstock/common/src/auth';
import useLoggedOutModal from '../public/useLoggedOutModal';
import { JoinModalPublic } from '../public/JoinModalPublic';
import { useTheme } from 'src/theme/ThemeContext';
import { useInView } from 'react-intersection-observer';
import { CardContentWrapper } from '../content/DetailContentWrapper';
import { CardLayoutTypes } from '../feed/constants';
const MemoDetail = dynamic(() => import('./MemoDetail'));
type Props = {
  memoFeedItem: MemoFeedItem;
  cardLayout: CardLayoutTypes;
  commentTarget?: string;
};

function MemoCard({
  memoFeedItem,
  cardLayout,
  commentTarget
}: Props) {
  const [inViewRef, inView] = useInView({
    triggerOnce: true
  });
  const {
    memo,
    reason_code
  } = destructMemoFeedItem(memoFeedItem);
  useTrackFeedItemImpression(memoFeedItem, inView);
  if (!memo) return null;
  const isCollapsed = reason_code === FeedReason.Pinned;

  if (isCollapsed) {
    cardLayout = CardLayoutTypes.Default;
  }

  return <CardContentWrapper ref={inViewRef} feedItem={memoFeedItem} cardLayout={cardLayout}>
      <MemoContent memoFeedItem={memoFeedItem} cardLayout={cardLayout} />
      {!isCollapsed && !!memo.comments?.count && <CardCommentSummary comments={memo.comments} parentType={ParentTypes.MEMO_PLUS} commentTarget={commentTarget} />}
    </CardContentWrapper>;
}

type MemoContentProps = {
  memoFeedItem: MemoFeedItem;
  detail?: boolean;
  cardLayout: CardLayoutTypes;
};
export function MemoContent({
  memoFeedItem,
  detail,
  cardLayout
}: MemoContentProps) {
  const {
    memo,
    user,
    mentionAttachments,
    linkedTrades,
    reason_code
  } = destructMemoFeedItem(memoFeedItem);
  if (!memo || !user) return null;
  const isCollapsed = reason_code === FeedReason.Pinned;
  return <MentionsContext.Provider value={mentionAttachments}>
      <ContentHeader user={user} feedItem={memoFeedItem} detail={!!detail} />
      <SpacerV />
      <Formatter text={memo.title} mentions={mentionAttachments} processor={memoMarkdown} className={titleClassSmall} />
      {!isCollapsed && <>
          <SpacerV rem={cardLayout === CardLayoutTypes.Upvote ? 0.25 : 1} />
          <MemoView preview={!detail} memoFeedItem={memoFeedItem} />
          <LinkedTrades feedItem={memoFeedItem} trades={linkedTrades} />
          <TagCloud tags={memo.tags} parentType={TagItemType.MEMO_PLUS} parentUuid={memoFeedItem.uuid} />
        </>}
      <ContentFooterActions feedItem={memoFeedItem} detail={detail} cardLayout={cardLayout} />
    </MentionsContext.Provider>;
}
export default React.memo(MemoCard);
const titleClassSmall = "t1hs8csk";
type MemoDetailLinkProps = {
  memoFeedItem: MemoFeedItem;
  children: ReactNode;
  cardLayout: CardLayoutTypes;
};
export const MemoDetailLink = ({
  memoFeedItem,
  children,
  cardLayout
}: MemoDetailLinkProps) => {
  const {
    isMobile
  } = useTheme();
  const modal = useEphemeralModal();
  const onClick = useCallback((e?: any) => {
    e?.preventDefault();
    e?.stopPropagation();
    trackMemoViewAction(memoFeedItem.uuid);
    modal(<MemoDetail isModal memoFeedItem={memoFeedItem} cardLayout={cardLayout} />, {
      shallowUrl: Routes.memoDetail(memoFeedItem.uuid),
      scope: ModalScope.Details
    });
  }, [memoFeedItem, modal, cardLayout]);
  const onKeyUp = useCallback((e: React.KeyboardEvent<HTMLElement>) => e.keyCode === 32 ? onClick(e) : null, [onClick]);
  const loggedOutModal = useLoggedOutModal('Memo Card');
  const handleClick = useCallback((e: React.MouseEvent<HTMLAnchorElement, globalThis.MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();

    if (!isLoggedIn()) {
      loggedOutModal('Join', <JoinModalPublic />);
      return;
    } // @NOTE: rIC will wait for default event behavior like clearing selecion, and only then it will check for selection lentgh


    requestIdleCallback(() => !document.getSelection()?.toString() && onClick(e), {
      timeout: 500
    });
  }, [loggedOutModal, onClick]);
  return isMobile || !isLoggedIn() ? <CSLink unstyled role="article" href={Routes.memoDetail(memoFeedItem.uuid)} tabIndex={0} className={cardLinkPointerClass}>
      {children}
    </CSLink> : // eslint-disable-next-line
  <article role="article" tabIndex={0} className={cardLinkPointerClass} onClick={handleClick} onKeyUp={onKeyUp}>
      {children}
    </article>;
};

require("../../../.linaria-cache/packages/oxcart/src/scopes/memo/MemoCard.linaria.module.css");