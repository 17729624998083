import { FeedResourceType, PostFeedItem, PostResource, TradeFeedItem } from '@commonstock/common/src/api/feed'
import { useMemo } from 'react'
import LinkedResourceCarousel from 'src/components/LinkedResourceCarousel'
import { SpacerV } from 'src/composing/Spacing'
import PostCardSmall from './PostCardSmall'

type Props = {
  tradeFeedItem: TradeFeedItem
  posts: PostResource[]
}

function LinkedPosts({ tradeFeedItem, posts }: Props) {
  const postFeedItems: PostFeedItem[] = useMemo(() => {
    return posts.map(post => ({
      ...tradeFeedItem,
      resource_type: FeedResourceType.POST,
      uuid: post.uuid,
      user_uuid: post.user_uuid
    }))
  }, [posts, tradeFeedItem])

  if (!postFeedItems.length) return null

  return (
    <>
      <LinkedResourceCarousel label="posts" height={5.9375}>
        {postFeedItems.map(postFeedItem => (
          <PostCardSmall key={postFeedItem.uuid} postFeedItem={postFeedItem} noBorder />
        ))}
      </LinkedResourceCarousel>
      <SpacerV />
    </>
  )
}

export default LinkedPosts
