import {
  FeedItem,
  FeedItemResources,
  MemoResource,
  PostResource,
  TradeFeedItem,
  TradeResource
} from '@commonstock/common/src/api/feed'
import { HydratedUserProfile } from '@commonstock/common/src/api/profile'
import { AssetMention } from '@commonstock/common/src/types/mentions'

type DestructedTrade = FeedItem & {
  trade?: TradeResource
  user?: HydratedUserProfile
  resources: FeedItemResources
  assets: { [key: string]: AssetMention }
  tradeAsset?: AssetMention
  linkedPosts: PostResource[]
  linkedMemos: MemoResource[]
}
export function destructTradeFeedItem(tradeFeedItem: TradeFeedItem): DestructedTrade {
  const { resources } = tradeFeedItem
  let trade: TradeResource | undefined = resources.trades[tradeFeedItem.uuid]
  let user: HydratedUserProfile | undefined = resources.users[tradeFeedItem.user_uuid]

  // BE return a wrong object when it fails to grab it from DB, so we need to check for the current uuid of them
  trade = tradeFeedItem.uuid === trade?.uuid ? trade : undefined
  user = tradeFeedItem.user_uuid === user?.uuid ? user : undefined

  const assets = resources.assets
  const tradeAsset = trade ? resources.assets[trade.asset_identifier] : undefined

  const linkedPosts: PostResource[] = trade?.related_posts?.map(id => resources.posts[id] || null).filter(Boolean) || []
  const linkedMemos: MemoResource[] = trade?.related_memos?.map(id => resources.memos[id] || null).filter(Boolean) || []

  return {
    ...tradeFeedItem,
    trade,
    user,
    assets,
    tradeAsset,
    linkedPosts,
    linkedMemos
  }
}
