import { useEffect, useRef } from 'react'

import { track } from '../mixpanel'
import {
  FeedResourceType,
  isMemoFeedItem,
  isPostFeedItem,
  isTradeFeedItem,
  MemoFeedItem,
  PostFeedItem,
  TradeFeedItem
} from '@commonstock/common/src/api/feed'
import { destructTradeFeedItem } from '../../trade/utils'
import { destructMemoFeedItem } from '../../memo/utils'
import { destructPostFeedItem } from '../../posts/utils'
import { useFeedType } from 'src/scopes/feed/utils'

export function useTrackImpression(key: string, data?: object, loading?: boolean) {
  useEffect(() => {
    if (loading) return
    track(key, data)
    // only trigger on mount or on key/loading change
  }, [key, loading]) // eslint-disable-line
}

export function useTrackMemoDetailImpression(memoFeedItem?: MemoFeedItem) {
  const isSent = useRef(false)
  useEffect(() => {
    if (memoFeedItem && !isSent.current) {
      const { memo, user } = destructMemoFeedItem(memoFeedItem)

      if (!memo || !user) return
      track('MemoDetail:Impression', {
        memo_uuid: memo.uuid,
        memo_creator_uuid: memo.user_uuid,
        creator_followed_status: user.follow_status,
        is_creator_verified: user.verified,
        mentioned_users: memo.user_mentions,
        mentioned_user_count: memo.user_mentions?.length,
        mentioned_assets: memo.asset_mentions,
        mentioned_asset_count: memo.asset_mentions?.length,
        comment_count: memo.comments?.count,
        like_dollar_value: memo.likes?.value || 0,
        published_at: memo.published_at,
        title: memo.title
      })
      isSent.current = true
    }
  }, [memoFeedItem])
}

export function useTrackTradeDetailImpression(tradeFeedItem?: TradeFeedItem) {
  const isSent = useRef(false)
  useEffect(() => {
    // @NOTE: Keeping the isTradeFeedItem to avoid tracking possible slips of tradeGroups
    if (tradeFeedItem && isTradeFeedItem(tradeFeedItem) && !isSent.current) {
      const { trade, user, tradeAsset } = destructTradeFeedItem(tradeFeedItem)
      if (!trade || !user) return
      track('TradeDetail:Impression', {
        order_uuid: trade.uuid,
        trade_creator_uuid: user.uuid,
        is_creator_verified: user.verified,
        with_memo: null,
        order_type: trade.transaction_type,
        asset_symbol: tradeAsset?.symbol,
        asset_type: tradeAsset?.type,
        comment_count: trade.comments?.count,
        like_dollar_value: trade.likes.value || 0,
        published_at: trade.created_at
      })
      isSent.current = true
    }
  }, [tradeFeedItem])
}

export function useTrackFeedItemImpression(
  feedItem?: MemoFeedItem | PostFeedItem | TradeFeedItem | undefined,
  inView?: boolean
) {
  const isSent = useRef(false)
  const { feedType } = useFeedType()
  useEffect(() => {
    // @NOTE: Keeping the isTradeFeedItem to avoid tracking possible slips of tradeGroups
    if (feedItem && isMemoFeedItem(feedItem) && !isSent.current && inView) {
      const { memo, user } = destructMemoFeedItem(feedItem)
      if (!memo || !user) return
      track('Feed Item Impression', {
        item_uuid: memo.uuid,
        item_type: FeedResourceType.MEMO_PLUS,
        owner_uuid: memo.user_uuid,
        owner_is_private: user.private,
        owner_is_verified: user.verified,
        owner_is_followed_by_me: user.followed_by_me,
        like_count: memo.likes.count,
        like_money: memo.likes.value,
        liked_by_me: memo.likes.liked_by_me,
        comment_count: memo.comments.count,
        reason_code: memo.reason_code,
        reason_text: memo.reason_text,
        feed_type: feedType
      })
      isSent.current = true
    }
    if (feedItem && isPostFeedItem(feedItem) && !isSent.current && inView) {
      const { post, user } = destructPostFeedItem(feedItem)
      if (!post || !user) return
      track('Feed Item Impression', {
        item_uuid: post.uuid,
        item_type: FeedResourceType.POST,
        owner_uuid: post.user_uuid,
        owner_is_private: user.private,
        owner_is_verified: user.verified,
        owner_is_followed_by_me: user.followed_by_me,
        like_count: post.likes.count,
        like_money: post.likes.value,
        liked_by_me: post.likes.liked_by_me,
        comment_count: post.comments.count,
        reason_code: feedItem.reason_code,
        reason_text: feedItem.reason_text,
        feed_type: feedType
      })
      isSent.current = true
    }
    if (feedItem && isTradeFeedItem(feedItem) && !isSent.current && inView) {
      const { trade, user } = destructTradeFeedItem(feedItem)
      if (!trade || !user) return
      track('Feed Item Impression', {
        item_uuid: trade.uuid,
        item_type: FeedResourceType.TRADE,
        owner_uuid: trade.user_uuid,
        owner_is_private: user.private,
        owner_is_verified: user.verified,
        owner_is_followed_by_me: user.followed_by_me,
        like_count: trade.likes.count,
        like_money: trade.likes.value,
        liked_by_me: trade.likes.liked_by_me,
        comment_count: trade.comments.count,
        reason_code: feedItem.reason_code,
        reason_text: feedItem.reason_text,
        feed_type: feedType
      })
      isSent.current = true
    }
  }, [feedItem, feedType, inView])
}

export enum ArticleType {
  Primary = 'Primary'
}
