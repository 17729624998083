import { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import { PrimaryButton, SecondaryButton } from 'src/composing/CSButton';
import { ActionModal } from 'src/scopes/modal/Modals';
import { useModal } from 'src/scopes/modal/Modal';
import { TextBodyRegular, TextDisplayLarge } from 'src/composing/CSText';
import { CheckCircle } from 'src/theme/NewIcons';
import { Colors } from 'src/theme/constants';
import useTwitterAuthCallback from '../auth/useTwitterAuthCallback';
import { useUser } from '../auth/AuthContext';
import useFetchSubmit from 'src/hooks/useFetchSubmit';
import { TwitterShareType, useShareToTwitterAction } from '@commonstock/common/src/api/link';
import { captureException } from '../../dev/sentry';
import { Routes } from '../nav/constants';
import { SpacerH, SpacerV } from 'src/composing/Spacing';
import { flexJustified } from 'src/theme/AtomicClasses';
import { css } from '@linaria/core';
export const SHARE_MEMO_QUERY = 'memo-twitter-share';
type Props = {
  uuid: string;
  redirectRoute?: string;
  closeAll?: boolean;
};

function MemoTwitterShare({
  uuid,
  redirectRoute,
  closeAll
}: Props) {
  const router = useRouter();
  const {
    actions
  } = useModal();
  const [profile] = useUser();
  const hasTwitterAuth = profile?.external_providers.some(p => p.canonical === 'twitter' && !p.is_stale);
  const authTwitter = useTwitterAuthCallback();
  const postToTwitter = useShareToTwitterAction();
  let {
    submit,
    success,
    pending
  } = useFetchSubmit(postToTwitter);
  const onShare = useCallback(async () => {
    if (success) return;

    if (hasTwitterAuth) {
      try {
        await submit({
          json: {
            item_type: TwitterShareType.MEMO_PLUS,
            item_uuid: uuid,
            external_providers: ['twitter']
          }
        });
      } catch (fail) {
        captureException(fail, {
          context: 'Failed to tweet memo'
        });
      }
    } else {
      authTwitter(`${window.location.origin}${Routes.memoDetail(uuid)}?${SHARE_MEMO_QUERY}=true`);
    }
  }, [success, hasTwitterAuth, submit, uuid, authTwitter]);
  const onFinish = useCallback(() => {
    redirectRoute && router.push(redirectRoute);
    closeAll ? actions.closeAllModals() : actions.close();
  }, [actions, closeAll, redirectRoute, router]);
  useEffect(() => {
    if (success) {
      // Add small delay to show a "Twitter sent" message to user
      setTimeout(onFinish, 1000);
    }
  }, [onFinish, success]);
  return <ActionModal title="">
      <CheckCircle color={Colors.TextSecondary} active size={4} className={iconClass} />
      <SpacerV rem={1.375} />
      <TextDisplayLarge centered>Your memo was published!</TextDisplayLarge>
      <SpacerV />
      <TextBodyRegular centered>Share your memo on Twitter</TextBodyRegular>
      <SpacerV rem={1.5} />
      <div className={flexJustified}>
        <SecondaryButton block large onClick={onFinish}>
          Skip
        </SecondaryButton>
        <SpacerH rem={1} />
        <PrimaryButton block onClick={onShare}>
          {pending ? 'Publishing...' : success ? 'Tweet Sent!' : 'Share on Twitter'}
        </PrimaryButton>
      </div>
    </ActionModal>;
}

export default MemoTwitterShare;
const iconClass = "i19njvw0";

require("../../../.linaria-cache/packages/oxcart/src/scopes/memo/MemoTwitterShare.linaria.module.css");