import { createUseFetch, createUseFetchAction } from '@commonstock/client/src/react/createUseFetch'
import { HttpMethods } from '@commonstock/client/src/constants'

type AssetMention = {
  symbol: string
  type: string
}
export type LinkPreview = {
  image_url: string
  link: string
  og_title: string
  og_description?: string
  content_owner_uuid: string
  asset_mentions: AssetMention[]
}

export enum ShareType {
  'TRADE' = 'trades',
  'MEMO_PLUS' = 'memos',
  'POST' = 'posts',
  'PROFILE' = 'profiles',
  'PORTFOLIO' = 'portfolios'
}
type LinkPreviewParams = {
  meta: {
    type: ShareType
    uuid: string
  }
  query?: {
    skip_cache?: boolean
    theme?: 'dark' | 'light'
  }
}
export const useGetLinkPreview = createUseFetch<LinkPreview, LinkPreviewParams>({
  key: 'create-link',
  path: ({ meta: { type, uuid } }) => `/link/${type}/${uuid}`
})

export const useGetLinkPreviewAction = createUseFetchAction<LinkPreview, LinkPreviewParams>({
  key: 'create-link-action',
  path: ({ meta: { type, uuid } }) => `/link/${type}/${uuid}`
})

export enum TwitterShareType {
  MEMO_PLUS = 'MEMO_PLUS',
  TRADE = 'TRADE'
}
type ShareTwitter = {
  image_url: string
  link: string
}
type ShareTwitterParams = {
  json: {
    item_uuid: string
    item_type: TwitterShareType
    external_providers: string[]
  }
}
export const useShareToTwitterAction = createUseFetchAction<ShareTwitter, ShareTwitterParams>({
  key: 'share-twitter',
  path: '/link/share',
  method: HttpMethods.Post
})
