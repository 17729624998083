import React, { ReactNode, useCallback } from 'react';
import { cardLinkPointerClass } from '../../components/styles';
import { SpacerV } from '../../composing/Spacing';
import { Routes } from '../nav/constants';
import TradeHeader from './TradeHeader';
import { css, cx } from '@linaria/core';
import CardCommentSummary from '../../components/CardCommentSummary';
import { ParentTypes } from '@commonstock/common/src/api/comment';
import { TradeFeedItem } from '@commonstock/common/src/api/feed';
import LinkedMemos from 'src/scopes/memo/LinkedMemos';
import { destructTradeFeedItem } from './utils';
import { ModalScope, useEphemeralModal } from '../modal/Modal';
import { trackTradeViewAction, useTrackFeedItemImpression } from '../analytics/events.v1';
import dynamic from 'next/dynamic';
import CSLink from 'src/composing/CSLink';
import { isLoggedIn } from '@commonstock/common/src/auth';
import LinkedPosts from '../posts/LinkedPosts';
import { useTheme } from 'src/theme/ThemeContext';
import { useInView } from 'react-intersection-observer';
import ContentHeader from '../content/ContentHeader';
import ContentFooterActions from '../content/ContentFooterActions';
import { TradeReason } from './TradeReason';
import { CardContentWrapper } from '../content/DetailContentWrapper';
import { CardLayoutTypes } from '../feed/constants';
const TradeDetail = dynamic(() => import('./TradeDetail'));
type Props = {
  tradeFeedItem: TradeFeedItem;
  portfolio?: boolean;
  cardLayout: CardLayoutTypes;
  commentTarget?: string;
};

function TradeCard({
  tradeFeedItem,
  portfolio,
  commentTarget,
  cardLayout
}: Props) {
  const [inViewRef, inView] = useInView({
    triggerOnce: true
  });
  const {
    trade
  } = destructTradeFeedItem(tradeFeedItem);
  useTrackFeedItemImpression(tradeFeedItem, inView);
  if (!trade) return null;
  return <CardContentWrapper ref={inViewRef} feedItem={tradeFeedItem} cardLayout={cardLayout}>
      <TradeContent tradeFeedItem={tradeFeedItem} portfolio={portfolio} cardLayout={cardLayout} />
      {!!trade.comments?.count && <CardCommentSummary comments={trade.comments} parentType={ParentTypes.TRADE} commentTarget={commentTarget} />}
    </CardContentWrapper>;
}

type TradeContentProps = {
  tradeFeedItem: TradeFeedItem;
  portfolio?: boolean | undefined;
  detail?: boolean;
  cardLayout: CardLayoutTypes;
};
export function TradeContent({
  tradeFeedItem,
  portfolio,
  detail,
  cardLayout
}: TradeContentProps) {
  const {
    user,
    linkedMemos,
    linkedPosts
  } = destructTradeFeedItem(tradeFeedItem);
  if (!user) return null;
  return <>
      <ContentHeader user={user} feedItem={tradeFeedItem} detail={!!detail} />
      <SpacerV />
      <TradeHeader tradeFeedItem={tradeFeedItem} isPortfolio={!!portfolio} />
      <SpacerV rem={0.75} />
      <LinkedPosts tradeFeedItem={tradeFeedItem} posts={linkedPosts} />
      <LinkedMemos tradeFeedItem={tradeFeedItem} memos={linkedMemos} />
      <TradeReason tradeFeedItem={tradeFeedItem} user={user} />
      <ContentFooterActions feedItem={tradeFeedItem} detail={detail} cardLayout={cardLayout} />
    </>;
}
export default React.memo(TradeCard);
type TradeDetailLinkProps = {
  tradeFeedItem?: TradeFeedItem;
  tradeUuid?: string;
  children: ReactNode;
  cardLayout: CardLayoutTypes;
};
export const TradeDetailLink = ({
  tradeFeedItem,
  tradeUuid,
  children,
  cardLayout
}: TradeDetailLinkProps) => {
  const {
    isMobile
  } = useTheme();
  const modal = useEphemeralModal();
  const uuid = tradeFeedItem?.uuid || tradeUuid || '';
  const onClick = useCallback((e?: any) => {
    e?.preventDefault();
    e?.stopPropagation();
    trackTradeViewAction(uuid);
    modal(<TradeDetail isModal tradeFeedItem={tradeFeedItem} uuid={tradeUuid} cardLayout={cardLayout} />, {
      shallowUrl: Routes.tradeDetail(uuid),
      scope: ModalScope.Details
    });
  }, [tradeFeedItem, modal, tradeUuid, uuid, cardLayout]);
  const onKeyUp = useCallback((e: React.KeyboardEvent<HTMLElement>) => e.keyCode === 32 ? onClick(e) : null, [onClick]);
  const handleClick = useCallback((e: React.MouseEvent<HTMLAnchorElement, globalThis.MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation(); // @NOTE: rIC will wait for default event behavior like clearing selecion, and only then it will check for selection lentgh

    requestIdleCallback(() => !document.getSelection()?.toString() && onClick(e), {
      timeout: 500
    });
  }, [onClick]);
  return isMobile || !isLoggedIn() ? <CSLink unstyled role="article" href={Routes.tradeDetail(uuid)} tabIndex={0} className={cx(cardLinkPointerClass, fullWidth)}>
      {children}
    </CSLink> : // eslint-disable-next-line
  <article role="article" tabIndex={0} className={cardLinkPointerClass} onClick={handleClick} onKeyUp={onKeyUp}>
      {children}
    </article>;
};
const fullWidth = "fia305z";

require("../../../.linaria-cache/packages/oxcart/src/scopes/trade/TradeCard.linaria.module.css");