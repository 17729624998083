import { FeedItem, isMemoFeedItem, isPostFeedItem, isTradeFeedItem } from '@commonstock/common/src/api/feed';
import { css, cx } from '@linaria/core';
import React, { ReactNode, Ref } from 'react';
import { ContentCard, DetailCard } from 'src/components/styles';
import { FlexRow } from 'src/composing/Flex';
import { scrollY } from 'src/theme/AtomicClasses';
import { CardLayoutTypes, isUpvoteLayout } from '../feed/constants';
import { MemoDetailLink } from '../memo/MemoCard';
import { PostDetailLink } from '../posts/PostCard';
import { TradeDetailLink } from '../trade/TradeCard';
import ContentColumnActions from './ContentColumnActions';
type Props = {
  feedItem: FeedItem;
  isModal?: boolean;
  children: ReactNode;
  cardLayout: CardLayoutTypes;
};
export function DetailContentWrapper({
  feedItem,
  isModal,
  children,
  cardLayout
}: Props) {
  const _isUpvoteLayout = isUpvoteLayout(cardLayout);

  return <DetailCard className={cx(isModal && 'modal', isModal && scrollY, _isUpvoteLayout && 'hasColumnActions')}>
      {_isUpvoteLayout ? <FlexRow>
          <ContentColumnActions feedItem={feedItem} />
          <div className={detailMainContentPadding}>{children}</div>
        </FlexRow> : children}
    </DetailCard>;
}
const detailMainContentPadding = "d1fak9ky";
type CardProps = {
  feedItem: FeedItem;
  children: ReactNode;
  cardLayout: CardLayoutTypes;
};
export const CardContentWrapper = React.forwardRef(({
  feedItem,
  children,
  cardLayout
}: CardProps, ref: Ref<HTMLDivElement>) => {
  const _isUpvoteLayout = isUpvoteLayout(cardLayout);

  return <ContentCard ref={ref} className={cx(!!feedItem.reason_text && 'hasReason', _isUpvoteLayout && 'hasColumnActions')}>
        <ContentLink feedItem={feedItem} cardLayout={cardLayout}>
          {_isUpvoteLayout ? <FlexRow>
              <ContentColumnActions feedItem={feedItem} />
              <div className={cardMainContentPadding}>{children}</div>
            </FlexRow> : children}
        </ContentLink>
      </ContentCard>;
});
CardContentWrapper.displayName = 'CardContentWrapper';
const cardMainContentPadding = "c1j5dbwu";
type ContentLinkProps = {
  feedItem: FeedItem;
  children: ReactNode;
  cardLayout: CardLayoutTypes;
};
export function ContentLink({
  feedItem,
  children,
  cardLayout
}: ContentLinkProps) {
  return <>
      {isMemoFeedItem(feedItem) ? <MemoDetailLink memoFeedItem={feedItem} cardLayout={cardLayout}>
          {children}
        </MemoDetailLink> : isPostFeedItem(feedItem) ? <PostDetailLink postFeedItem={feedItem} cardLayout={cardLayout}>
          {children}
        </PostDetailLink> : isTradeFeedItem(feedItem) ? <TradeDetailLink tradeFeedItem={feedItem} cardLayout={cardLayout}>
          {children}
        </TradeDetailLink> : null}
    </>;
}

require("../../../.linaria-cache/packages/oxcart/src/scopes/content/DetailContentWrapper.linaria.module.css");