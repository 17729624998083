import React, { useCallback, useState } from 'react'
import { More } from '../../theme/NewIcons'
import { IconButton } from '../../composing/CSButton'
import { CardIcon, Menu, MenuItem } from '../../components/styles'
import useEventListener from '@commonstock/common/src/utils/useEventListener'
import FollowProfile from '../profile/FollowProfile'
import { useGetProfile } from '@commonstock/common/src/api/profile'
import { MuteToggleItem } from '../profile/ProfileButtons'
import { useAuth } from '../auth/AuthContext'
import MuteTrades from './MuteTrades'
import { MemoFeedItem, TradeFeedItem } from '@commonstock/common/src/api/feed'
import { destructTradeFeedItem } from './utils'
import { useEphemeralModal } from '../modal/Modal'
import { useUpdateRelatedMemoAction } from '@commonstock/common/src/api/related'
import { unexpectedError } from '../../dev/sentry'
import { convertResourcesToFeedItems } from '../../utils/feedItems'
import { useRefetchMemoDependents } from '../memo/memoHooks'
import dynamic from 'next/dynamic'

const TradeShareModal = dynamic(async () => {
  const module = await import('../share/ShareModal')
  return module.TradeShareModal
})
const LinkContentModal = dynamic(() => import('../link-content/LinkContentModal'))

function TradeCardActions({ tradeFeedItem }: { tradeFeedItem: TradeFeedItem }) {
  let { user } = destructTradeFeedItem(tradeFeedItem)

  const [isOpen, setIsOpen] = useState(false)
  const { userUuid } = useAuth()
  const [userProfile] = useGetProfile({ meta: { user_uuid: tradeFeedItem.user_uuid } }, { paused: !isOpen })
  user = userProfile ? { ...userProfile, uuid: userProfile.user_uuid } : user

  const onClickMenu = useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()
    e.stopPropagation()
    setIsOpen(prev => !prev)
  }, [])

  const isMyTrade = userUuid === tradeFeedItem.user_uuid

  const refetchDependents = useRefetchMemoDependents()
  const linkMemos = useUpdateRelatedMemoAction()
  const modal = useEphemeralModal()
  const gotoLinkMemo = () => {
    const { memoFeedItems } = convertResourcesToFeedItems(tradeFeedItem)
    const onClick = async ({ memos }: { memos: MemoFeedItem[] }) => {
      try {
        await linkMemos({ meta: { tradeId: tradeFeedItem.uuid }, json: { linked_items: memos.map(m => m.uuid) } })
        refetchDependents()
      } catch (err) {
        unexpectedError(err)
      }
    }
    modal(<LinkContentModal showMemos onClick={onClick} memos={memoFeedItems} />)
  }

  useEventListener('click', (e: any) => !e.target.closest(`#actions-${tradeFeedItem.uuid}`) && setIsOpen(false))

  const handleShare = useCallback(() => modal(<TradeShareModal tradeFeedItem={tradeFeedItem} />), [
    modal,
    tradeFeedItem
  ])

  if (!user) return null
  return (
    <CardIcon id={`actions-${tradeFeedItem.uuid}`}>
      <IconButton onClick={onClickMenu} aria-label={'Show more trade actions'}>
        <More active={isOpen} size={1.25} />
      </IconButton>
      <Menu hidden={!isOpen}>
        <MenuItem disabled={userProfile?.private} onClick={handleShare}>
          {userProfile?.private ? 'Share disabled' : 'Share trade'}
        </MenuItem>
        {!isMyTrade ? (
          <>
            <FollowProfile profile={user} isDropdown />
            <MuteTrades tradeFeedItem={tradeFeedItem} />
            <MuteToggleItem profile={user} />
          </>
        ) : (
          <MenuItem onClick={gotoLinkMemo}>Link memo</MenuItem>
        )}
      </Menu>
    </CardIcon>
  )
}

export default TradeCardActions
